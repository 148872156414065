.diagram {
    width: 526px;
    max-width: 100%;
}

line {
    stroke-width: 6px;
}

path {
    stroke-width: 6px;
    stroke: #000;
    fill: none;
}

path.base {
    stroke: lightgray;
}

path.active {
    stroke-dasharray: 20;
    animation: dash 60s linear;
    stroke-width: 12px;
}

.siding {
    font-size: 40px;
    font-weight: bold;
}

@keyframes dash {
    to {
        stroke-dashoffset: -3000;
    }
}
