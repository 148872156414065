.schedule {
    font-size: 20px;
}

.schedule-train {
    display: flex;
    margin-bottom: 8px;
}

.schedule-time {
    flex: 0 0 auto;
    width: 65px;
    font-weight: bold;
}

.schedule-right {
    flex: 1 1 auto;
    min-width: 0;
}

.in-view .schedule-right {
    padding-left: 57px;
}

.schedule-right .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.locations {
    display: inline-block;
    margin-top: 8px;
    height: 22px;
}

.location {
    display: inline-block;
    border: 1px solid black;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.location:last-child {
    margin-right: 0;
}

.location.passed {
    background-color: lightgray;
}

.location.target {
    background-color: orange;
}

.location.latest {
    background-color: black;
}

.location.off {
    background-color: red;
}
